import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const Page = styled(Box)`
  box-sizing: content-box;
  min-height: 100vh;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.16);
`;

export default ({ background = 'white', ...props }) => (
  <Page
    backgroundColor={background}
    mt={-theme.page.borderRadius}
    pt={theme.page.paddingYWithBorderRadius}
    borderRadius={theme.page.borderRadius}
    {...props}
  />
);
