import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';
import { label } from '@/styles/typography';
import { media, scale } from '@/styles/utils';

export default styled(Box).attrs(props => ({
  color: props.color || 'black',
}))`
  ${label}
  display: inline-block;
  height: ${scale(1.125)};
  padding: 0 ${scale(0.5)};
  border: 1px solid ${props => theme.color[props.color]};
  border-radius: ${scale(0.5625)};
  line-height: ${scale(1.125)};
  text-align: center;

  ${props =>
    props.circle &&
    `
    padding: 0;
    width: ${scale(1.125)};
  `}

  ${media.lg`
    height: ${scale(1.375)};
    line-height: ${scale(1.375)};
    border-radius: ${scale(0.6875)};

    ${props =>
      props.circle &&
      `
      width: ${scale(1.375)};
    `}
  `}
`;
