import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Asset from '@/components/Asset';

import { getImageSizes } from '@/utils/dataTransformation';

const Image = styled(Img)`
  img:-moz-loading {
    visibility: hidden;
  }
`;

export default ({ image, alt, settings, columnWidth = 1, ...props }) => {
  if (!image) {
    return null;
  }

  // fluid image with custom srcset sizes
  const imageProps = {
    fluid: {
      ...image.asset.fluid,
      sizes: getImageSizes(columnWidth),
    },
    alt,
  };

  return (
    <Asset
      dimensions={image.asset.metadata.dimensions}
      {...settings}
      {...props}
    >
      <Image {...imageProps} />
    </Asset>
  );
};

export const query = graphql`
  fragment imageData on SanityAtomImage {
    image {
      asset {
        fluid(maxWidth: 2400) {
          ...GatsbySanityImageFluid_withWebp
        }
        metadata {
          dimensions {
            width
            height
          }
        }
      }
    }
    alt
    settings {
      ... on SanityAssetSettings {
        ...assetSettingsData
      }
    }
  }
`;
