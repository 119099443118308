import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const Card = styled(Box)`
  border: ${props => (props.border ? `1px solid ${props.border}` : 'none')};
`;

export default ({
  size = 'normal',
  backgroundColor = 'gray.onWhite.lightest',
  ...props
}) => (
  <Card
    px={theme.card[size].paddingX}
    pt={theme.card[size].paddingTop}
    pb={theme.card[size].paddingBottom}
    backgroundColor={backgroundColor}
    borderRadius={theme.card[size].borderRadius}
    {...props}
  />
);
