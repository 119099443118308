import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Grid from '@/atoms/Grid';
import Image from '@/components/Image';
import Video from '@/components/Video';

export default ({
  columnWidth,
  type,
  assetProps,
  handleScrolledTo,
  ...props
}) => {
  const [ref, inView] = useInView({
    rootMargin: '0px -49% 0px -49%',
  });

  useEffect(() => {
    if (inView) {
      handleScrolledTo();
    }
  }, [inView]);

  return (
    <Grid.Item displayFlex="flex" ref={ref} w={columnWidth} {...props}>
      {type === 'atomImage' && <Image {...assetProps} />}
      {type === 'atomVideo' && <Video {...assetProps} />}
    </Grid.Item>
  );
};
