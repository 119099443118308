import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import ProjectCard from '@/components/ProjectCard';
import Separator from '@/components/Separator';

import theme from '@/styles/theme';

export default ({
  label,
  projects,
  colorScheme,
  spacing = 'large',
  ...props
}) => {
  const columnWidth = [1, 1, 1 / 2];

  return (
    <Grid.Container as="section" pb={theme.spacing[spacing]} {...props}>
      <Separator label={label} mb={theme.spacing.xsmall} />

      <Grid mb={theme.grid.gutter.map(g => -g)}>
        {projects.map(({ id, ...projectProps }) => (
          <Grid.Item
            key={id}
            w={columnWidth}
            mb={theme.grid.gutter}
            displayFlex="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <ProjectCard w={1} columnWidth={columnWidth} {...projectProps} />
          </Grid.Item>
        ))}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleProjectsOverviewData on SanityModuleProjectsOverview {
    label
    projects {
      id: _id
      ... on SanityProject {
        ...projectCardData
      }
    }
    spacing
  }
`;
