import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';
import PortableText from '@/atoms/PortableText';

import theme from '@/styles/theme';

const AuthorLogo = styled(Img)`
  display: inline-block;
  vertical-align: middle;
  width: 50%;
  height: auto;
  max-width: 180px;
`;

export default ({
  author,
  authorLogo,
  textRaw,
  colorScheme,
  spacing = 'large',
  size,
  ...props
}) => {
  const textSize = size === 'large' ? 'h3' : 2;
  const maxWidth = size === 'large' ? undefined : theme.grid.maxTextWidth;

  return (
    <Grid.Container pb={theme.spacing[spacing]} {...props}>
      <RichText as="div" t={textSize} maxWidth={maxWidth}>
        <PortableText blocks={textRaw} />

        {authorLogo ? (
          <div>
            — <AuthorLogo fluid={authorLogo.asset.fluid} alt={author} />
          </div>
        ) : (
          <p>— {author}</p>
        )}
      </RichText>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleQuoteData on SanityModuleQuote {
    author
    authorLogo {
      asset {
        fluid(maxWidth: 180) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
    size
    spacing
    textRaw: _rawText
  }
`;
