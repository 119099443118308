import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import Button from '@/atoms/Button';
import Separator from '@/components/Separator';
import SeparatorWithText from '@/components/SeparatorWithText';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const TextWithLine = styled(Text)`
  position: relative;

  ${media.md`
    ::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 150%;
      height: 1px;
      background: ${theme.color.black};
    }
  `}
`;

const Link = styled.a`
  border-bottom: 1px solid transparent;
  transition: all 0.15s ease-out;

  :hover,
  :focus {
    border-bottom-color: inherit;
  }
`;

export default ({
  label,
  text = null,
  contactPerson,
  email,
  phone,
  showButton = false,
  buttonLabel,
  colorScheme,
  spacing = 'large',
  ...props
}) => {
  const hasText = text !== null;

  const ContactInfo = (
    <>
      {contactPerson && (
        <>
          <Text t={2} as="span" textColor={`gray.${colorScheme}.medium`}>
            {contactPerson}
          </Text>
          <br />
        </>
      )}
      {email && (
        <>
          <Link
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {email}
          </Link>
          <br />
        </>
      )}
      {phone && (
        <>
          <Link href={`tel:${phone}`}>{phone}</Link>
          <br />
        </>
      )}
      {showButton && (
        <>
          <Button
            as="a"
            href={`mailto:${email}`}
            target="_blank"
            rel="noopener noreferrer"
            mt={[1.75, 1.75, 2.25]}
          >
            {buttonLabel} →
          </Button>
        </>
      )}
    </>
  );

  return (
    <Grid.Container pb={theme.spacing[spacing]} {...props}>
      <Grid mb={theme.spacing.medium.map(s => -s)}>
        <Grid.Item
          w={[1, 1, 1 / 2]}
          o={hasText ? 0 : [0, 0, 1 / 2]}
          mb={theme.spacing.medium}
        >
          {hasText ? (
            <SeparatorWithText label={label} pr={[0, 0, 4]}>
              {text}
            </SeparatorWithText>
          ) : (
            <Separator label={label}>
              <Text t={2} pt={1.25}>
                {ContactInfo}
              </Text>
            </Separator>
          )}
        </Grid.Item>

        {hasText && (
          <Grid.Item w={[1, 1, 1 / 2]} mb={theme.spacing.medium}>
            <TextWithLine t={2} pt={[0.25, 0.25, 0.375]}>
              {ContactInfo}
            </TextWithLine>
          </Grid.Item>
        )}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleContactInfoData on SanityModuleContactInfo {
    label
    text
    contactPerson
    email
    phone
    showButton
    buttonLabel
    spacing
  }
`;
