import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Tag from '@/atoms/Tag';

import { scale } from '@/styles/utils';

const Tags = styled.div``;

const TagList = styled.ul`
  li {
    margin-right: ${scale(0.375)};
  }
`;

const Content = styled(Box)`
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-decoration: none;
`;

export default ({
  title,
  categories,
  areasOfWork,
  shortDescription,
  textColor,
  isLink,
  linkText,
  ...props
}) => {
  return (
    <Content
      px={[0.825, 1, 1, 1, 1.25]}
      pt={[0.75, 0.875, 0.875, 0.875, 1.125]}
      pb={[0.825, 1, 1, 1, 1.25]}
      textColor={textColor}
      displayFlex="flex"
      flexDirection="column"
      justifyContent="space-between"
      {...props}
    >
      <Tags>
        <TagList>
          {areasOfWork.map(item => (
            <Tag key={item.title} as="li" color={textColor}>
              {item.title}
            </Tag>
          ))}
        </TagList>
      </Tags>

      <div>
        <Heading h={3} mb={0.25}>
          {title}
        </Heading>

        <Text>{shortDescription}</Text>
      </div>
    </Content>
  );
};
