import React from 'react';
import PortableText from '@sanity/block-content-to-react';

import InlineImageSet from '@/components/InlineImageSet';

const serializers = {
  marks: {
    link: ({ children, mark }) => (
      <a href={mark.url} target="_blank" rel="noopener noreferrer">
        {children[0]}
      </a>
    ),
  },
  types: {
    atomImageSet: ({ node: { images } }) => <InlineImageSet images={images} />,
  },
};

export default ({ blocks, ...props }) => {
  return <PortableText blocks={blocks} serializers={serializers} {...props} />;
};
