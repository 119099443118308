import React from 'react';

import Box from '@/atoms/Box';
import PizzaLeft from './PizzaLeft';
import PizzaRight from './PizzaRight';

export default props => (
  <Box displayFlex="flex" {...props}>
    <PizzaLeft mr="2%" />
    <PizzaRight />
  </Box>
);
