import React from 'react';

import Grid from '@/atoms/Grid';
import PortableText from '@/atoms/PortableText';
import RichText from '@/atoms/RichText';
import Heading from '@/atoms/Heading';

import theme from '@/styles/theme';

export default ({ headline, textRaw }) => {
  return (
    <>
      <Grid.Item w={[1, 1, 1, 9 / 12]}>
        <Heading
          h={2}
          as="h2"
          pt={[0.5, 0.5, 0.75]}
          maxWidth={theme.grid.maxTextWidth}
          hyphensMobile
        >
          {headline}
        </Heading>
      </Grid.Item>

      <Grid.Item w={[1, 1, 1, 9 / 12]}>
        <RichText
          as="div"
          t={2}
          pt={[1, 1, 1, 1.5]}
          maxWidth={theme.grid.maxTextWidth}
          whiteSpace="pre-line"
          hyphensMobile
        >
          <PortableText blocks={textRaw} />
        </RichText>
      </Grid.Item>
    </>
  );
};
