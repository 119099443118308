import React from 'react';
import { graphql } from 'gatsby';

import Assets from '@/modules/Assets';
import Columns from '@/modules/Columns';
import ContactInfo from '@/modules/ContactInfo';
import Copy from '@/modules/Copy';
import Facts from '@/modules/Facts';
import HeadlineCopy from '@/modules/HeadlineCopy';
import HeadlineWithIntro from '@/modules/HeadlineWithIntro';
import Moodboard from '@/modules/Moodboard';
import PizzaIntro from '@/modules/PizzaIntro';
import ProjectsOverview from '@/modules/ProjectsOverview';
import ProjectsOverviewCategorized from '@/modules/ProjectsOverviewCategorized';
import Quote from '@/modules/Quote';
import RichText from '@/modules/RichText';
import Services from '@/modules/Services';
import Svg from '@/modules/Svg';

const colorScheme = {
  white: 'onWhite',
  red: 'onRed',
};

export default ({ contentModules, background = 'white' }) => {
  const { modules } = contentModules || { modules: [] };

  return (
    <>
      {modules.map(({ id, type, ...props }) => {
        let module = null;
        const moduleProps = {
          key: id,
          colorScheme: colorScheme[background],
          ...props,
        };

        if (type === 'moduleAssets') {
          module = <Assets {...moduleProps} />;
        } else if (type === 'moduleColumns') {
          module = <Columns {...moduleProps} />;
        } else if (type === 'moduleContactInfo') {
          module = <ContactInfo {...moduleProps} />;
        } else if (type === 'moduleCopy') {
          module = <Copy {...moduleProps} />;
        } else if (type === 'moduleFacts') {
          module = <Facts {...moduleProps} />;
        } else if (type === 'moduleHeadlineCopy') {
          module = <HeadlineCopy {...moduleProps} />;
        } else if (type === 'moduleHeadlineWithIntro') {
          module = <HeadlineWithIntro {...moduleProps} />;
        } else if (type === 'moduleMoodboard') {
          module = <Moodboard {...moduleProps} />;
        } else if (type === 'modulePizzaIntro') {
          module = <PizzaIntro {...moduleProps} />;
        } else if (type === 'moduleProjectsOverview') {
          module = <ProjectsOverview {...moduleProps} />;
        } else if (type === 'moduleProjectsOverviewCategorized') {
          module = <ProjectsOverviewCategorized {...moduleProps} />;
        } else if (type === 'moduleQuote') {
          module = <Quote {...moduleProps} />;
        } else if (type === 'moduleRichText') {
          module = <RichText {...moduleProps} />;
        } else if (type === 'moduleServices') {
          module = <Services {...moduleProps} />;
        } else if (type === 'moduleSvg') {
          module = <Svg {...moduleProps} />;
        }

        return module;
      })}
    </>
  );
};

export const query = graphql`
  fragment contentModulesData on SanityContentModules {
    modules {
      ... on SanityModuleAssets {
        id: _key
        type: _type
        ...moduleAssetsData
      }
      ... on SanityModuleColumns {
        id: _key
        type: _type
        ...moduleColumnsData
      }
      ... on SanityModuleContactInfo {
        id: _key
        type: _type
        ...moduleContactInfoData
      }
      ... on SanityModuleCopy {
        id: _key
        type: _type
        ...moduleCopyData
      }
      ... on SanityModuleFacts {
        id: _key
        type: _type
        ...moduleFactsData
      }
      ... on SanityModuleHeadlineCopy {
        id: _key
        type: _type
        ...moduleHeadlineCopyData
      }
      ... on SanityModuleHeadlineWithIntro {
        id: _key
        type: _type
        ...moduleHeadlineWithIntroData
      }
      ... on SanityModuleMoodboard {
        id: _key
        type: _type
        ...moduleMoodboardData
      }
      ... on SanityModulePizzaIntro {
        id: _key
        type: _type
        ...modulePizzaIntroData
      }
      ... on SanityModuleProjectsOverview {
        id: _key
        type: _type
        ...moduleProjectsOverviewData
      }
      ... on SanityModuleProjectsOverviewCategorized {
        id: _key
        type: _type
        ...moduleProjectsOverviewCategorizedData
      }
      ... on SanityModuleQuote {
        id: _key
        type: _type
        ...moduleQuoteData
      }
      ... on SanityModuleRichText {
        id: _key
        type: _type
        ...moduleRichTextData
      }
      ... on SanityModuleServices {
        id: _key
        type: _type
        ...moduleServicesData
      }
      ... on SanityModuleSvg {
        id: _key
        type: _type
        ...moduleSvgData
      }
    }
  }
`;
