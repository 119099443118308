import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Card from '@/atoms/Card';
import Button from '@/atoms/Button';
import Text from '@/atoms/Text';
import Logo from '@/components/Logo';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const Headline = styled.h1`
  margin: 0;
  font-size: 8.6vw;
  line-height: 1;
  font-weight: normal;
  letter-spacing: -0.04em;
  white-space: pre-line;

  ${media.sm`
    font-size: 8.2vw;
    line-height: 0.95;
  `}

  ${media.lg`
    font-size: 8.1vw;
  `}
`;

const LOGO_RATIO = 0.51;

const InlineLogo = styled(Logo)`
  position: relative;
  bottom: -1.2vw;
  display: inline-flex;
  width: 24vw;
  height: ${24 * LOGO_RATIO}vw;

  ${media.sm`
    bottom: -0.6vw;
    width: 17vw;
    height: ${17 * LOGO_RATIO}vw;
  `}

  ${media.lg`
    bottom: -0.5vw;
    width: 16.25vw;
    height: ${16.25 * LOGO_RATIO}vw;
  `}

  /* responsive svgs on IE */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    vertical-align: top;
    bottom: 1vw;
  }
`;

export default ({ headline, colorScheme, spacing = 'large', ...props }) => {
  return (
    <Grid.Container
      mt={theme.page.paddingY.map((v, i) => -v + theme.grid.outerMargin[i])}
      pb={theme.spacing[spacing]}
      {...props}
    >
      <Headline>
        <InlineLogo /> is a digital product studio crafting memorable customer
        experiences
      </Headline>

      <Card
        backgroundColor="gray.onRed.lightest"
        mt={theme.spacing.medium}
        maxWidth="800px"
      >
        <Text t={1}>
          Pizza Pizza is now part of Forte Digital – an international digital
          consultancy focusing on the effective trifactor of technology,
          strategy and design.
        </Text>

        <Button
          mt={[0.75, 0.75, 1, 1.5]}
          size="medium"
          as="a"
          href="https://fortedigital.de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more →
        </Button>
      </Card>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment modulePizzaIntroData on SanityModulePizzaIntro {
    headline
    spacing
  }
`;
