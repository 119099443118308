import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Card from '@/atoms/Card';
import Text from '@/atoms/Text';

import theme from '@/styles/theme';
import { stripedBackground } from '@/styles/utils';

const contentColorScheme = {
  onWhite: 'onGray',
  onRed: 'onRedGray',
};

const getVariantProps = (variant, colorScheme, contentScheme) => {
  if (variant === 'outlines') {
    return {
      border: 'black',
      backgroundColor: 'transparent',
      headlineColor: 'black',
      textColor: `gray.${contentScheme}.medium`,
    };
  }

  if (variant === 'smallTitle') {
    return {
      border: null,
      backgroundColor: `gray.${colorScheme}.lightest`,
      headlineColor: `gray.${contentScheme}.medium`,
      textColor: 'black',
    };
  }

  return {
    border: null,
    backgroundColor: `gray.${colorScheme}.lightest`,
    headlineColor: 'black',
    textColor: `gray.${contentScheme}.medium`,
  };
};

const TitleSmall = styled(Text)`
  ${props =>
    stripedBackground(
      '100%',
      theme.color.gray[props.colorScheme].light,
      'to top'
    )};
`;

export default ({
  title,
  variant = 'default',
  text,
  colorScheme = 'onWhite',
  ...props
}) => {
  const contentScheme = contentColorScheme[colorScheme];
  const variantProps = getVariantProps(variant, colorScheme, contentScheme);

  return (
    <Card
      size="normal"
      border={variantProps.border}
      backgroundColor={variantProps.backgroundColor}
      {...props}
    >
      {variant === 'smallTitle' ? (
        <TitleSmall
          as="h3"
          textColor={variantProps.headlineColor}
          pb={0.25}
          mb={0.5}
          colorScheme={contentScheme}
        >
          {title}
        </TitleSmall>
      ) : (
        <Text as="h3" t={1} textColor={variantProps.headlineColor} pb={0.5}>
          {title}
        </Text>
      )}

      <Text
        textColor={variantProps.textColor}
        whiteSpace="pre-line"
        maxWidth={theme.grid.maxTextWidthSmall}
        hyphensMobile
        hyphens
      >
        {text}
      </Text>
    </Card>
  );
};

export const query = graphql`
  fragment cardData on SanityAtomCard {
    text
    title
    variant
  }
`;
