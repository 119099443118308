/* eslint-disable import/prefer-default-export */
import imageUrlBuilder from '@sanity/image-url';

const imageBuilder = imageUrlBuilder({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET,
});

export const sanityImage = source => {
  return imageBuilder.image(source);
};
