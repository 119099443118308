import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Box from '@/atoms/Box';
import Text from '@/atoms/Text';

import theme from '@/styles/theme';
import { media, scale, stripedBackground } from '@/styles/utils';

const List = styled(Text)`
  box-sizing: content-box;
  padding-bottom: 1px;
  height: 100%;
  list-style: none;

  ${props =>
    stripedBackground(
      scale(1.87),
      theme.color.gray[props.colorScheme].light,
      'to bottom'
    )};

  ${media.md`
      ${props =>
        stripedBackground(
          scale(1.87),
          theme.color.gray[props.colorScheme].light,
          'to bottom'
        )};
  `}

  ${media.lg`
      ${props =>
        stripedBackground(
          scale(2.06),
          theme.color.gray[props.colorScheme].light,
          'to bottom'
        )};
  `}

  li {
    padding-top: ${scale(0.4)};
    padding-bottom: ${scale(0.35)};

    a {
      border-bottom: 1px solid transparent;
      transition: all 0.15s ease-out;

      :hover,
      :focus {
        border-bottom-color: inherit;
      }
    }
  }
`;

export default ({ title, items, colorScheme = 'onWhite', ...props }) => {
  return (
    <Box {...props}>
      <Text as="h3" textColor={`gray.${colorScheme}.medium`} pb={0.25}>
        {title}
      </Text>

      <List as="ul" colorScheme={colorScheme}>
        {items.map(item => (
          <li key={item} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
      </List>
    </Box>
  );
};

export const query = graphql`
  fragment listData on SanityAtomList {
    title
    items: list
  }
`;
