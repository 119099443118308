import styled from 'styled-components';

import Box from '@/atoms/Box';

import { media, visuallyHidden } from '@/styles/utils';
import { h1, h2, h3, h4 } from '@/styles/typography';

export default styled(Box).attrs(props => ({
  as: props.as || `h${props.h}`,
  removeMobileWhitespace: props.removeMobileWhitespace || false,
}))`
  ${props => props.h === 1 && h1}
  ${props => props.h === 2 && h2}
  ${props => props.h === 3 && h3}
  ${props => props.h === 4 && h4}

  ${props => props.hide && visuallyHidden}

  ${props =>
    !props.removeMobileWhitespace &&
    `
    white-space: pre-line;
  `}

  hyphens: ${props => (props.hyphensMobile ? 'auto' : 'none')};

  ${media.lg`
    white-space: pre-line;
    hyphens: ${props => (props.hyphens ? 'auto' : 'none')};
  `}
`;
