import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Separator from '@/components/Separator';

import theme from '@/styles/theme';
import Box from '@/atoms/Box';
import Heading from '@/atoms/Heading';
import ProjectsSlider from '@/components/ProjectsSlider';

export default ({
  label,
  projectsCategories,
  colorScheme,
  spacing = 'large',
  ...props
}) => {
  return (
    <Box as="section" pb={theme.spacing[spacing]} {...props}>
      <Box mb={theme.spacing.large.map(m => -m)}>
        <Grid.Container>
          <Separator label={label} mb={theme.spacing.xsmall} />
        </Grid.Container>

        {projectsCategories.map(({ id, title, projects }) => (
          <Box key={id} mb={theme.spacing.large}>
            <Grid.Container mb={theme.spacing.medium}>
              <Heading h={2}>{title}</Heading>
            </Grid.Container>

            <ProjectsSlider projects={projects} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export const query = graphql`
  fragment moduleProjectsOverviewCategorizedData on SanityModuleProjectsOverviewCategorized {
    label
    projectsCategories {
      id: _key
      title
      text
      projects {
        id: _id
        ... on SanityProject {
          ...projectCardData
        }
      }
    }
    spacing
  }
`;
