import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Card from '@/atoms/Card';
import Label from '@/atoms/Label';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import List from '@/components/List';

import theme from '@/styles/theme';
import { scale } from '@/styles/utils';

const Number = styled(Label)`
  margin-top: -${scale(0.5)};
  margin-right: ${scale(0.75)};
  vertical-align: middle;
`;

const contentColorScheme = {
  onWhite: 'onGray',
  onRed: 'onRedGray',
};

export default ({
  number,
  title,
  text,
  columnsPerRow,
  columns,
  colorScheme,
  ...props
}) => {
  return (
    <Card
      as="article"
      size="large"
      backgroundColor={`gray.${colorScheme}.lightest`}
      w={1}
      {...props}
    >
      <Heading h={3} mb={[0.5, 0.5, 0.5, 0.75]}>
        <Number as="span" circle>
          {number}
        </Number>
        {title}
      </Heading>

      <Grid>
        <Text
          as={Grid.Item}
          t={1}
          w={[1, 1, 1, 1 / columnsPerRow]}
          maxWidth={[
            theme.grid.maxTextWidthSmall,
            theme.grid.maxTextWidthSmall,
            theme.grid.maxTextWidthSmall,
            theme.grid.maxTextWidth,
          ]}
        >
          {text}
        </Text>
      </Grid>

      {columns && columns.length > 0 && (
        <Grid mt={[2, 2, 2.5]} mb={theme.spacing.small.map(s => -s)} pb={0.5}>
          {columns.map(({ id, ...colProps }) => (
            <Grid.Item
              key={id}
              w={[1, 1 / columnsPerRow]}
              mb={theme.spacing.small}
            >
              <List
                ht="100%"
                colorScheme={contentColorScheme[colorScheme]}
                {...colProps}
              />
            </Grid.Item>
          ))}
        </Grid>
      )}
    </Card>
  );
};

export const query = graphql`
  fragment serviceData on SanityAtomService {
    columnsPerRow
    text
    title
    columns {
      id: _key
      items: list
      title
    }
  }
`;
