import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Separator from '@/components/Separator';

import theme from '@/styles/theme';
import { getGridWidth } from '@/utils/dataTransformation';

export default ({ facts, colorScheme, spacing = 'large', ...props }) => {
  return (
    <Grid.Container as="section" pb={theme.spacing[spacing]} {...props}>
      <Grid mb={theme.spacing.large.map(s => -s)}>
        {facts.map(({ id, label, text, width }) => {
          return (
            <Grid.Item
              key={id}
              w={getGridWidth(width)}
              mb={theme.spacing[spacing]}
            >
              <Separator label={label} inline>
                <Heading h={2} pt={0.75} as="p">
                  {text}
                </Heading>
              </Separator>
            </Grid.Item>
          );
        })}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleFactsData on SanityModuleFacts {
    facts {
      id: _key
      label
      text
      width
    }
    spacing
  }
`;
