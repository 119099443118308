import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import PortableText from '@/atoms/PortableText';
import SeparatorWithText from '@/components/SeparatorWithText';

import theme from '@/styles/theme';

export default ({
  label,
  text,
  textRaw,
  colorScheme,
  spacing = 'large',
  children,
  ...props
}) => {
  return (
    <Grid.Container pb={theme.spacing[spacing]} {...props}>
      <SeparatorWithText label={label}>
        {text && <p>{text}</p>}
        {textRaw && <PortableText blocks={textRaw} />}
      </SeparatorWithText>

      {children}
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleCopyData on SanityModuleCopy {
    label
    textRaw: _rawText
    spacing
  }
`;
