import styled from 'styled-components';

import Label from '@/atoms/Label';

import { media, scale } from '@/styles/utils';

export default styled(Label)`
  text-transform: none;
  line-height: ${scale(1.1)};

  ${media.lg`
    height: ${scale(1.125)};
    border-radius: ${scale(0.5625)};
    line-height: ${scale(1.125)};
  `}
`;
