import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import BackgroundImage from '@/components/BackgroundImage';
import Item from './Item';
import Indicators from './Indicators';

import theme from '@/styles/theme';
import { media, scale } from '@/styles/utils';

const GridItem = styled(Item)`
  margin-top: ${scale(theme.spacing.gutter[0])};

  ${media.sm`
    margin-top: ${scale(theme.spacing.gutter[1])};
  `}

  ${media.md`
    margin-top: 0;
  `}

  :first-child {
    ${props =>
      !props.reverseOnMobile &&
      `
      margin-top: 0;
    `}
  }

  :last-child {
    ${props =>
      props.reverseOnMobile &&
      `
      margin-top: 0;
    `}
  }
`;

const ScrollCrop = styled.div`
  overflow: hidden;
`;

const Wrapper = styled.div`
  ${props =>
    props.background &&
    `
    background: ${props.background.hex};
  `}

  ${props =>
    props.isSwipeable &&
    `
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    margin-top: -20px;
    padding-bottom: 20px;
    transform: translateY(20px);
  `}

  ${media.md`
    scroll-snap-type: none;
    overflow: visible;
    margin-top: 0;
    padding-bottom: 0;
    transform: none;
  `}

  ${GridItem} {
    ${props =>
      props.isSwipeable &&
      `
      margin-top: 0;
      padding-left: 0;
      padding-right: 0;
      scroll-snap-align: center;
      scroll-snap-stop: always;
    `}
    ${props =>
      props.isSwipeable &&
      props.background &&
      `
      overflow: hidden;
    `}

    ${media.md`
      padding-left: ${scale(theme.grid.gutter[2] / 2)};
      padding-right: ${scale(theme.grid.gutter[2] / 2)};
    `}

    ${media.lg`
      padding-left: ${scale(theme.grid.gutter[3] / 2)};
      padding-right: ${scale(theme.grid.gutter[3] / 2)};
    `}
  }
`;

const AssetGrid = styled(Grid)`
  ${props =>
    props.isSwipeable &&
    `
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
  `}

  ${media.md`
    flex-wrap: wrap;
    margin-left: -${scale(theme.grid.gutter[2] / 2)};
    margin-right: -${scale(theme.grid.gutter[2] / 2)};
  `}

  ${media.lg`
    margin-left: -${scale(theme.grid.gutter[3] / 2)};
    margin-right: -${scale(theme.grid.gutter[3] / 2)};
  `}
`;

const AssetContainer = styled(Grid.Container)`
  position: relative;

  ${media.belowsm`
    ${props =>
      props.fullWidth &&
      `
      padding-left: 0;
      padding-right: 0;
    `}
  `}
`;

const ScrollContainer = styled.div`
  position: relative;
`;

const SwipeIndicators = styled(Indicators)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${p => (p.hasBackground ? scale(-1.5) : scale(0.5))};

  ${media.md`
    display: none;
  `}
`;

export default ({
  assets,
  spacing = 'large',
  layout,
  background,
  backgroundImage,
  swipeable,
  reverseOnMobile,
  colorScheme,
  ...props
}) => {
  const [activeItem, setActiveItem] = useState(0);

  const handleScrolledToItem = i => {
    setActiveItem(i);
  };

  const isSwipeable = swipeable && assets.length > 1;
  const columnWidth = [1, 1, 1 / assets.length];
  let gridProps = {};

  if (layout === 'inset') {
    gridProps = {
      ...gridProps,
      px: [0, 0, 0, 2, 6],
    };
  }

  const GridItems = assets.map(({ id, type, ...assetProps }, i) => {
    const finalAssetProps = {
      ...assetProps,
      columnWidth,
      parentBackground: background,
    };

    let flexOrder = 0;

    if (reverseOnMobile) {
      flexOrder = [assets.length - i, assets.length - i, 0];
    }

    return (
      <GridItem
        key={id}
        type={type}
        columnWidth={columnWidth}
        reverseOnMobile={reverseOnMobile}
        flexOrder={flexOrder}
        assetProps={finalAssetProps}
        handleScrolledTo={() => handleScrolledToItem(i)}
      />
    );
  });

  return (
    <AssetContainer
      fullWidth={!background && !backgroundImage && isSwipeable}
      pb={theme.spacing[spacing]}
      {...props}
    >
      {background || backgroundImage || isSwipeable ? (
        <ScrollContainer>
          <ScrollCrop>
            <Wrapper background={background} isSwipeable={isSwipeable}>
              {backgroundImage && <BackgroundImage image={backgroundImage} />}

              <AssetGrid isSwipeable={isSwipeable} {...gridProps}>
                {GridItems}
              </AssetGrid>
            </Wrapper>
          </ScrollCrop>

          {isSwipeable && (
            <SwipeIndicators
              numItems={assets.length}
              activeItem={activeItem}
              hasBackground={!!background}
            />
          )}
        </ScrollContainer>
      ) : (
        <AssetGrid {...gridProps}>{GridItems}</AssetGrid>
      )}
    </AssetContainer>
  );
};

export const query = graphql`
  fragment moduleAssetsData on SanityModuleAssets {
    assets {
      ... on SanityAtomImage {
        id: _key
        type: _type
        ...imageData
      }
      ... on SanityAtomVideo {
        id: _key
        type: _type
        ...videoData
      }
    }
    spacing
    layout
    swipeable
    reverseOnMobile
    background {
      hex
    }
    backgroundImage {
      ...backgroundImageData
    }
  }
`;
