import React from 'react';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';
import Card from '@/components/Card';
import List from '@/components/List';

import theme from '@/styles/theme';

const GRID_VALUES_FOR_COLUMNS = {
  'cols-1': [1],
  'cols-2': [1, 1, 1 / 2],
  'cols-3': [1, 1, 1 / 3],
  'cols-4': [1, 1, 1 / 2, 1 / 2, 1 / 4],
};

export default ({
  columnsPerRow: columnsPerRowUnsafe = 3,
  columnWidth,
  columns,
  colorScheme = 'onWhite',
  ...props
}) => {
  const columnsPerRow = parseInt(columnsPerRowUnsafe, 10);
  const columnType = columns && columns[0].type;
  const columnSpacing =
    columnType === 'atomCard' ? theme.grid.gutter : theme.spacing.small;

  return (
    <Grid mb={columnSpacing.map(s => -s)} {...props}>
      {columns.map(({ id, type, ...col }) => {
        let component = null;
        let gridItemW =
          columnWidth || GRID_VALUES_FOR_COLUMNS[`cols-${columnsPerRow}`];
        let gridItemOr = 0;

        const colProps = {
          colorScheme,
          ht: '100%',
          ...col,
        };

        if (type === 'atomCard') {
          component = <Card {...colProps} />;
        } else if (type === 'atomList') {
          component = <List {...colProps} />;
        } else if (type === 'atomText') {
          const { text, ...textProps } = colProps;

          if (columnsPerRow === 2) {
            gridItemW = [1, 1, 5 / 12];
            gridItemOr = [0, 0, 1 / 12];
          }

          component = (
            <Text
              whiteSpace="pre-line"
              maxWidth={theme.grid.maxTextWidthSmall}
              {...textProps}
            >
              {text}
            </Text>
          );
        }

        return (
          <Grid.Item key={id} w={gridItemW} or={gridItemOr} mb={columnSpacing}>
            {component}
          </Grid.Item>
        );
      })}
    </Grid>
  );
};
