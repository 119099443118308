import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';
import Label from '@/atoms/Label';

import theme from '@/styles/theme';
import { media, scale } from '@/styles/utils';

const Separator = styled(Box)`
  position: relative;
  line-height: 1;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: ${props => (props.withLabel ? scale(1) : 0)};
    height: 1px;
    width: ${props => (props.withLabel ? `calc(100% - ${scale(1)})` : '100%')};
    background: ${theme.color.black};
  }
`;

const InlineLabel = styled(Label)`
  ${props =>
    props.inlineMobile &&
    `
    float: left;
    margin-right: ${scale(1.25)};
  `}

  ${media.md`
    ${props =>
      props.inline &&
      `
      float: left;
      margin-right: ${scale(2)};
    `}
  `}
`;

export default ({
  label,
  children,
  inlineMobile = false,
  inline = false,
  ...props
}) => (
  <Separator withLabel={label !== null} {...props}>
    {label && (
      <InlineLabel as="h2" inlineMobile={inlineMobile} inline={inline}>
        {label}
      </InlineLabel>
    )}

    {children}
  </Separator>
);
