import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import Asset from '@/components/Asset';
import VideoPlayer from '@/components/VideoPlayer';

export default ({ video, alt, settings, columnWidth = 1, ...props }) => {
  const [isPlaying, setPlaying] = useState(false);
  const [inViewRef, inView] = useInView({
    rootMargin: '-30% 0px -30% 0px',
  });

  useEffect(() => {
    setPlaying(inView);
  }, [inView]);

  if (video) {
    const srcSetSorted = video.srcset
      .filter(src => src.width)
      .sort((srcA, srcB) => srcB.width - srcA.width);

    return (
      <Asset
        dimensions={{
          width: srcSetSorted[0].width,
          height: srcSetSorted[0].height,
        }}
        {...settings}
        {...props}
      >
        {/* divs mimic DOM of Img */}
        <div>
          <div ref={inViewRef}>
            <VideoPlayer
              vimeoVideo={video}
              columnWidth={columnWidth}
              autoplay={false}
              play={isPlaying}
              preload
              loop
              muted
              playsInline
            />
          </div>
        </div>
      </Asset>
    );
  }

  return null;
};

export const query = graphql`
  fragment videoData on SanityAtomVideo {
    video {
      ... on SanityVimeo {
        ...vimeoData
      }
    }
    alt
    settings {
      ... on SanityAssetSettings {
        ...assetSettingsData
      }
    }
  }
`;
