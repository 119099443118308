import React from 'react';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import RichText from '@/atoms/RichText';
import PortableText from '@/atoms/PortableText';

import theme from '@/styles/theme';

export default ({ headline, textRaw, colorScheme }) => {
  return (
    <>
      <Grid.Item w={[1, 1, 5 / 12]} or={[0, 0, 1 / 12]}>
        <Heading
          h={3}
          as="h3"
          pt={[0.25, 0.25, 0.375]}
          maxWidth={theme.grid.maxTextWidth}
          hyphensMobile
          removeMobileWhitespace
        >
          {headline}
        </Heading>
      </Grid.Item>

      <Grid.Item w={[1, 1, 1 / 2]}>
        <RichText
          as="div"
          t={1}
          pt={[0.5, 0.5, 0.825, 1]}
          maxWidth={theme.grid.maxTextWidth}
          whiteSpace="pre-line"
          textColor={`gray.${colorScheme}.medium`}
          hyphens
        >
          <PortableText blocks={textRaw} />
        </RichText>
      </Grid.Item>
    </>
  );
};
