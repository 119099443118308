import React from 'react';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';
import { scale } from '@/styles/utils';

const Dot = styled(Box).attrs({
  mx: '3px',
})`
  border: 1px solid ${theme.color.black};
  width: ${scale(0.5)};
  height: ${scale(0.5)};
  border-radius: 50%;
  background: ${props => (props.isActive ? theme.color.black : 'transparent')};
`;

export default ({ numItems, activeItem, ...props }) => (
  <Box displayFlex="flex" justifyContent="center" {...props}>
    {Array(numItems)
      .fill(1)
      .map((a, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Dot isActive={i === activeItem} key={i} />
      ))}
  </Box>
);
