import React, { useState } from 'react';
import styled from 'styled-components';
import { sanityImage } from '@/utils/sanity';

import useInterval from '@/hooks/useInterval';

import { media, scale, rem } from '@/styles/utils';

const Container = styled.span`
  position: relative;
  display: inline-block;
  margin-top: -2px;
  margin-left: ${scale(0.125)};
  width: 160px;
  height: ${rem(24)};
  vertical-align: middle;

  ${media.lg`
    margin-top: -4px;
    margin-left: ${scale(0.25)};
    height: ${rem(32)};
  `}
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: auto;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
`;

export default ({ images, ...props }) => {
  const [activeItem, setActiveItem] = useState(0);

  useInterval(() => {
    setActiveItem((activeItem + 1 + images.length) % images.length);
  }, 600);

  return (
    <Container {...props}>
      {images.map(({ _key: key, asset: { _ref: assetId } }, i) => (
        <Image
          key={key}
          isActive={activeItem === i}
          src={sanityImage(assetId)
            .height(32)
            .maxWidth(160)
            .fit('max')
            .dpr(2)
            .url()}
        />
      ))}
    </Container>
  );
};
