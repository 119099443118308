import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import SeparatorWithText from '@/components/SeparatorWithText';
import PortableText from '@/atoms/PortableText';
import RichText from '@/atoms/RichText';

import theme from '@/styles/theme';

export default ({
  label,
  headlineRaw,
  introRaw,
  textRaw,
  colorScheme,
  size,
  spacing = 'large',
  ...props
}) => {
  return (
    <Grid.Container pb={theme.spacing[spacing]} {...props}>
      <SeparatorWithText label={label}>
        {introRaw && <PortableText blocks={introRaw} />}
      </SeparatorWithText>

      {headlineRaw && (
        <RichText
          as="div"
          pt={2}
          t={size === 'large' ? 'h2' : 'h3'}
          whiteSpace="pre-line"
          hyphens
        >
          <PortableText blocks={headlineRaw} />
        </RichText>
      )}

      {textRaw && (
        <RichText as="div" pt={2} t={2} whiteSpace="pre-line" hyphensMobile>
          <PortableText blocks={textRaw} />
        </RichText>
      )}
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleHeadlineWithIntroData on SanityModuleHeadlineWithIntro {
    label
    headlineRaw: _rawHeadline
    introRaw: _rawIntro
    textRaw: _rawText
    size
    spacing
  }
`;
