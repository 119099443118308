import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Box from '@/atoms/Box';
import Content from './Content';

import { getImageSizes } from '@/utils/dataTransformation';
import theme from '@/styles/theme';
import { media } from '@/styles/utils';
import Image from '@/components/Image';

const BackgroundImage = styled(Img)`
  transform: scale(1.001);
  transition: transform 0.5s ease-out;

  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${theme.color.black};
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
`;

const Screen = styled(Image)`
  z-index: 1;
  position: absolute;
  bottom: 25vw;
  height: 70vw;

  ${p =>
    p.type === 'desktop' &&
    `
    left: 10%;
    width: 80%;
  `}

  ${p =>
    p.type === 'iphonex' &&
    `
    left: 27%;
    width: 46%;
  `}

  ${media.sm`
    bottom: 25vw;
    height: 40vw;
  `}

  ${media.md`
    bottom: 15vw;
    height: 40vw;

    ${p =>
      p.type === 'desktop' &&
      `
      left: 11%;
      width: 78%;
    `}

    ${p =>
      p.type === 'iphonex' &&
      `
      left: 32%;
      width: 36%;
    `}
  `}

  ${media.lg`
    bottom: 12vw;
    height: 30vw;
  `}

  ${media.xl`
    bottom: 5rem;
    height: 30vw;
  `}
`;

const Card = styled(Box).attrs({
  borderRadius: theme.card.large.borderRadius,
})`
  position: relative;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  ${props =>
    props.isLink &&
    `
    ${BackgroundImage} {
      ::after {
        opacity: 0.1;
      }
    }

    :focus,
    :hover {
      ${BackgroundImage} {
        transform: scale(1.015);

        ::after {
          opacity: 0;
        }
      }
    }
  `};
`;

export default ({
  columnWidth,
  title,
  slug,
  linkToDetail,
  linkToExternal,
  textColor,
  categories,
  areasOfWork,
  shortDescription,
  previewImage,
  previewImageMobile,
  previewScreen,
  previewScreenFrame,
  ...props
}) => {
  // fluid image with custom srcset sizes and possibly art directed for mobile
  const imageProps =
    previewImageMobile && previewImageMobile.asset
      ? {
          fluid: [
            {
              ...previewImageMobile.asset.fluid,
              sizes: getImageSizes(columnWidth),
            },
            {
              ...previewImage.asset.fluid,
              sizes: getImageSizes(columnWidth),
              media: `(min-width: ${theme.breakpoints[3]})`,
            },
          ],
        }
      : {
          fluid: {
            ...previewImage.asset.fluid,
            sizes: getImageSizes(columnWidth),
          },
        };

  const contentProps = {
    title,
    categories,
    areasOfWork,
    shortDescription,
    textColor: textColor || 'black',
  };

  return (
    <Card as="article" isLink={linkToDetail || linkToExternal} {...props}>
      {linkToDetail ? (
        <Link to={`/work/${slug.current}`} state={{ asOverlay: true }}>
          <Content isLink linkText="View Case Study" {...contentProps} />
        </Link>
      ) : linkToExternal ? (
        <Box as="a" href={linkToExternal} target="_blank">
          <Content isLink linkText="View Website" {...contentProps} />
        </Box>
      ) : (
        <Content isLink={false} {...contentProps} />
      )}

      {previewScreen && (
        <Screen
          type={previewScreenFrame}
          image={previewScreen}
          settings={{ frame: previewScreenFrame }}
          columnWidth={props.columnWidth}
        />
      )}

      <BackgroundImage {...imageProps} />
    </Card>
  );
};

export const query = graphql`
  fragment projectCardData on SanityProject {
    title
    slug {
      current
    }
    linkToDetail
    linkToExternal
    textColor
    categories {
      title
    }
    areasOfWork {
      title
    }
    previewImage {
      asset {
        fluid(maxWidth: 2400) {
          ...GatsbySanityImageFluid_withWebp
        }
        metadata {
          dimensions {
            width
            height
          }
        }
      }
    }
    previewImageMobile {
      asset {
        fluid(maxWidth: 2400) {
          ...GatsbySanityImageFluid_withWebp
        }
        metadata {
          dimensions {
            width
            height
          }
        }
      }
    }
    previewScreen {
      asset {
        fluid(maxWidth: 2400) {
          ...GatsbySanityImageFluid_withWebp
        }
        metadata {
          dimensions {
            width
            height
          }
        }
      }
    }
    previewScreenFrame
    shortDescription
  }
`;
