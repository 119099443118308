import React, { useRef, useEffect, useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Box from '@/atoms/Box';

import { getVideoData } from './utils';

const Video = styled(Box).attrs({ as: 'video' })`
  display: block;
  width: 100%;
  height: auto;
`;

export default ({
  vimeoVideo,
  columnWidth = 1,
  play = false,
  preload = false,
  autoplay = false,
  loop = false,
  ...props
}) => {
  const videoRef = useRef(null);
  const [hasAutoplayed, setHasAutoplayed] = useState(false);
  const [isPlaying, setPlaying] = useState(false);

  const { poster, srcset } = getVideoData(vimeoVideo, columnWidth);

  // Video controls with promises
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  const pauseVideo = useCallback(() => {
    if (isPlaying) {
      videoRef.current.pause();
    }
  }, [isPlaying, videoRef]);

  const playVideo = useCallback(() => {
    const playPromise = videoRef.current.play();

    if (playPromise !== undefined) {
      playPromise.then(() => {
        setPlaying(true);

        // make sure we still want to be playing the video when it started
        if (!play) {
          pauseVideo();
        }
      });
    }
  }, [play, pauseVideo, videoRef]);

  // video can be started and stopped by setting play true/false
  useEffect(() => {
    if (videoRef.current) {
      if (play) {
        playVideo();
      } else if (!autoplay || (autoplay && hasAutoplayed)) {
        pauseVideo();
        setHasAutoplayed(true);
      }
    }
  }, [play, autoplay, hasAutoplayed, pauseVideo, playVideo]);

  if (srcset && srcset.length > 0) {
    return (
      <Video
        ref={videoRef}
        poster={poster}
        autoPlay={autoplay}
        preload={preload ? 'auto' : 'metadata'}
        loop={loop}
        {...props}
      >
        {srcset.map(src => (
          <source
            key={src.link}
            media={src.mediaQuery}
            type="video/mp4"
            src={src.link}
          />
        ))}
      </Video>
    );
  }

  return null;
};

export const query = graphql`
  fragment vimeoData on SanityVimeo {
    srcset {
      link
      width
      height
    }
    pictures {
      link
      width
      height
    }
  }
`;
