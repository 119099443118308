import { forceArray } from '@/utils/dataTransformation';
import theme from '@/styles/theme';

const breakpointToPixels = bp => parseInt(bp.replace('em', ''), 10) * 16;

const getColumnWidthsForSrcset = (columnWidths, srcset) => {
  const columnWidthsArray = forceArray(columnWidths);
  const breakpointsInPixels = theme.breakpoints.map(bp =>
    breakpointToPixels(bp)
  );

  // get the min. width a video should have per breakpoint,
  // taking into account the corresponding column width
  // and that we always display in retina resolution (1/2 size of original file)
  const videoMinWidthsByColumnWidths = columnWidthsArray.map(
    (cWidth, i) => breakpointsInPixels[i] * cWidth * 2
  );

  const columnWidthsForVideoWidths = srcset.map(({ width }) => {
    // width is smaller than what's needed for the smallest breakpoint
    if (width < videoMinWidthsByColumnWidths[0]) {
      return columnWidthsArray[0];
    }

    // find matching breakpoint
    const columnIndex = videoMinWidthsByColumnWidths.findIndex(
      minWidth => width >= minWidth
    );

    return columnWidthsArray[columnIndex];
  });

  return columnWidthsForVideoWidths;
};

export const getSrcsetWithMediaQueries = (
  srcset,
  columnWidth,
  baseMediaQuery = null
) => {
  // filter out hls file, videos under 500px width
  // order srcset by asc video width for generating queries
  const videoFilters =
    srcset.length > 4 ? src => src.width && src.width > 500 : src => src.width;

  const sanitizedSrcset = srcset
    .filter(videoFilters)
    .sort((srcA, srcB) => srcA.width - srcB.width);

  // get column widths (1, 1/2…) for each src
  const columnWidthsForSrcset = getColumnWidthsForSrcset(
    columnWidth,
    sanitizedSrcset
  );

  const sanitizedSrcsetWithMediaQueries = sanitizedSrcset.map((src, i) => {
    // breakpoint equals the "max" display size (in retina) of the image before
    const breakpoint =
      i === 0
        ? null
        : sanitizedSrcset[i - 1].width / 2 / columnWidthsForSrcset[i];

    let mediaQueryParts = breakpoint ? [`(min-width: ${breakpoint}px)`] : [];

    if (baseMediaQuery) {
      mediaQueryParts = [...mediaQueryParts, baseMediaQuery];
    }

    return {
      ...src,
      mediaQuery:
        mediaQueryParts.length > 0 ? mediaQueryParts.join(' and ') : undefined,
    };
  });

  // return in reversed order
  return sanitizedSrcsetWithMediaQueries.reverse();
};

export const getVideoData = (vimeoVideo, columnWidth) => {
  let srcsetWithMediaQueries;
  let poster;

  // check if regular video or art directed
  if (Array.isArray(vimeoVideo)) {
    // art directed video (array)
    const artDirectedSrcsetsWithMediaQueries = vimeoVideo.map(
      ({ video, media }) =>
        getSrcsetWithMediaQueries(video.srcset, columnWidth, media)
    );

    // flatten array
    srcsetWithMediaQueries = artDirectedSrcsetsWithMediaQueries.reduce(
      (acc, cur) => [...acc, ...cur],
      []
    );

    // use transparent png as poster
    poster =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  } else {
    // normal video
    srcsetWithMediaQueries = getSrcsetWithMediaQueries(
      vimeoVideo.srcset,
      columnWidth
    );

    const sanitizedSrcset = vimeoVideo.srcset.sort(
      (srcA, srcB) => srcA.width - srcB.width
    );
    const src = sanitizedSrcset[sanitizedSrcset.length - 1];

    // we use the last (largest) picture as poster and replace the last part of the CDN url to make sure the image has the correct size
    const originalPoster =
      vimeoVideo.pictures[vimeoVideo.pictures.length - 1].link;

    poster = originalPoster.replace(
      /-d_\d*x\d*\?r=pad/g,
      `-d_${src.width}x${src.height}`
    );
  }

  return {
    srcset: srcsetWithMediaQueries,
    poster,
  };
};
