import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Separator from '@/components/Separator';
import Default from './Default';
import Large from './Large';

import theme from '@/styles/theme';

export default ({
  label,
  headline,
  textRaw,
  size,
  colorScheme,
  spacing = 'large',
  ...props
}) => {
  const contentProps = { headline, textRaw, colorScheme };

  const Content =
    size === 'large' ? (
      <Large {...contentProps} />
    ) : (
      <Default {...contentProps} />
    );

  return (
    <Grid.Container pb={theme.spacing[spacing]} {...props}>
      <Separator label={label} />

      <Grid>{Content}</Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleHeadlineCopyData on SanityModuleHeadlineCopy {
    label
    headline
    textRaw: _rawText
    size
    spacing
  }
`;
