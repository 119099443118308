import React from 'react';

import RichText from '@/atoms/RichText';
import Separator from '@/components/Separator';

import theme from '@/styles/theme';

export default ({ label, children, ...props }) => {
  return (
    <Separator label={label} inline inlineMobile {...props}>
      <RichText
        as="div"
        t={2}
        pt={[0.25, 0.25, 0.375]}
        maxWidth={theme.grid.maxTextWidth}
        whiteSpace="pre-line"
        hyphensMobile
      >
        {children}
      </RichText>
    </Separator>
  );
};
