import React from 'react';

import Grid from '@/atoms/Grid';
import Box from '@/atoms/Box';
import ProjectCard from '@/components/ProjectCard';

import { media } from '@/styles/utils';
import styled from 'styled-components';

const COLUMN_WIDTH = [10.5 / 12, 8 / 12, 6.5 / 12, 5 / 12, 3.4 / 12];

const Wrapper = styled(Box)`
  position: relative;
`;

const Slider = styled(Box)`
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.75rem;
  scrollbar-width: none;
  scrollbar-color: #ccc #f3f3f3;

  ${media.md`
    gap: 1rem;
    padding-bottom: 1.5rem;
    scrollbar-width: unset;
  `}

  ${media.lg`
    gap: 2.5rem;
  `}

  &&::-webkit-scrollbar {
    display: none;
    height: 0.625rem;

    ${media.md`
      display: unset;
    `}
  }

  &&::-webkit-scrollbar-track {
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 99px;
    background: #f3f3f3;

    ${media.lg`
      margin-left: 2.5rem;
      margin-right: 2.5rem;
    `}
  }

  &&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 2px #f3f3f3;
    background: #ccc;
    border-radius: 99px;
  }

  &&::-webkit-scrollbar-thumb:hover {
    background: #999;
  }

  && > div {
    padding: 0;
  }
`;

const Spacer = styled.div`
  flex: 0 0 auto;
  height: 100px;
  width: 1px;
`;

export default ({ projects, ...props }) => {
  return (
    <Wrapper {...props}>
      <Slider>
        <Spacer />

        {projects.map(({ id: projectId, ...projectProps }) => (
          <Grid.Item
            key={projectId}
            w={COLUMN_WIDTH}
            displayFlex="flex"
            flexDirection="column"
            justifyContent="flex-end"
          >
            <ProjectCard w={1} columnWidth={COLUMN_WIDTH} {...projectProps} />
          </Grid.Item>
        ))}

        <Spacer />
      </Slider>
    </Wrapper>
  );
};
