import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import PortableText from '@/atoms/PortableText';
import Columns from '@/components/Columns';
import Separator from '@/components/Separator';
import SeparatorWithText from '@/components/SeparatorWithText';

import theme from '@/styles/theme';
import { getGridWidth } from '@/utils/dataTransformation';

export default ({
  label,
  textRaw,
  spacing = 'large',
  columnsPerRow = 3,
  moduleWidth,
  moduleAlignment,
  columns,
  colorScheme,
  ...props
}) => {
  const width = moduleWidth !== null ? moduleWidth : '1_1';
  const gridWidth = getGridWidth(width);

  return (
    <Grid.Container as="section" pb={theme.spacing[spacing]} {...props}>
      <Grid>
        <Grid.Item w={gridWidth} o={gridWidth.map(w => 1 - w)}>
          {label &&
            (textRaw ? (
              <SeparatorWithText label={label} mb={theme.spacing.small}>
                {textRaw && <PortableText blocks={textRaw} />}
              </SeparatorWithText>
            ) : (
              <Separator label={label} mb={theme.spacing.xsmall} />
            ))}

          <Columns
            columnsPerRow={columnsPerRow}
            columns={columns}
            columnWidth={
              moduleWidth && moduleWidth !== '1_1'
                ? [1, 1, 1, 1, 1 / columnsPerRow]
                : undefined
            }
            colorScheme={colorScheme}
          />
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleColumnsData on SanityModuleColumns {
    label
    textRaw: _rawText
    spacing
    columnsPerRow
    moduleWidth
    moduleAlignment
    columns {
      ... on SanityAtomCard {
        id: _key
        type: _type
        ...cardData
      }
      ... on SanityAtomList {
        id: _key
        type: _type
        ...listData
      }
      ... on SanityAtomText {
        id: _key
        type: _type
        text
      }
    }
  }
`;
