import React from 'react';
import styled from 'styled-components';

import Text from '@/atoms/Text';
import PortableText from '@/atoms/PortableText';

import theme from '@/styles/theme';
import { p } from '@/styles/typography';

const RichText = styled(Text)`
  h5 {
    ${p}
    color: ${props => theme.color.gray[props.colorScheme].medium};
  }

  p, ul, ol {
    padding-bottom: 0.45em;

    :last-child {
      padding-bottom: 0;
    }
  }

  ul, ol {
    padding-left: 1.25em;
  }

  ul {
    list-style: none;

    li {
      position: relative;

      ::before {
        content: "—";
        position: absolute;
        top: 0;
        left: -1.25em;
      }
    }
  }

  a {
    border-bottom: 1px solid currentColor;
  }
`;

export default ({ blocksRaw, colorScheme = 'onWhite', ...props }) => {
  return (
    <RichText
      as={PortableText}
      blocks={blocksRaw}
      colorScheme={colorScheme}
      {...props}
    />
  );
};
