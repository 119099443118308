import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import SeparatorWithText from '@/components/SeparatorWithText';
import Service from './Service';

import theme from '@/styles/theme';
import { getGridWidth } from '@/utils/dataTransformation';

export default ({
  label,
  text,
  services,
  colorScheme,
  spacing = 'large',
  ...props
}) => {
  return (
    <Grid.Container as="section" pb={theme.spacing[spacing]} {...props}>
      <SeparatorWithText label={label} mb={theme.spacing.medium}>
        {text}
      </SeparatorWithText>

      <Grid mb={theme.grid.gutter.map(s => -s)}>
        {services.map(({ id, width, ...serviceProps }, i) => {
          return (
            <Grid.Item
              key={id}
              w={getGridWidth(width)}
              mb={theme.grid.gutter}
              displayFlex="flex"
            >
              <Service
                number={i + 1}
                colorScheme={colorScheme}
                flex="1 1 auto"
                {...serviceProps}
              />
            </Grid.Item>
          );
        })}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleServicesData on SanityModuleServices {
    label
    text
    services {
      id: _key
      width
      ...serviceData
    }
    spacing
  }
`;
