import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Item from './Item';

import theme from '@/styles/theme';

export default ({ items, colorScheme, spacing = 'large', ...props }) => {
  return (
    <Grid.Container pb={theme.spacing[spacing]} {...props}>
      <Grid>
        {items.map(({ id, type, ...itemProps }) => {
          return <Item key={id} type={type} {...itemProps} />;
        })}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleMoodboardData on SanityModuleMoodboard {
    items {
      ... on SanityAtomMoodboardImage {
        id: _key
        type: _type
        ...moodboardImageData
      }
      ... on SanityAtomMoodboardVideo {
        id: _key
        type: _type
        ...moodboardVideoData
      }
    }
    spacing
  }
`;
