import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';
import Separator from '@/components/Separator';

import theme from '@/styles/theme';

export default ({
  label,
  textRaw,
  colorScheme,
  spacing = 'large',
  ...props
}) => {
  return (
    <Grid.Container pb={theme.spacing[spacing]} {...props}>
      <Separator label={label} />

      <RichText
        blocksRaw={textRaw}
        colorScheme={colorScheme}
        pt={theme.spacing.xsmall}
        maxWidth={theme.grid.maxTextWidth}
      />
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleRichTextData on SanityModuleRichText {
    label
    textRaw: _rawText
    spacing
  }
`;
