import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Image from '@/components/Image';
import Video from '@/components/Video';

import { media } from '@/styles/utils';

const defaultLayout = {
  gridOffset: null,
  gridWidth: null,
  xOffsetDirection: null,
  xOffsetValue: null,
  yOffsetValue: null,
  zIndex: null,
};

const getGridValues = (mobileValue, desktopValue, defaultValue = 0) => {
  const mValue = mobileValue || defaultValue;
  const dValue = desktopValue || defaultValue;

  return [mValue / 12, mValue / 12, dValue / 12];
};

const getOffset = (direction, value) => {
  const dir = direction || 'left';
  const val = value || 0;

  return {
    left: dir === 'left' ? val : 'auto',
    right: dir === 'right' ? val : 'auto',
  };
};

const Item = styled(Grid.Item)`
  order: ${props => props.gridOrder[0]};
  position: relative;
  z-index: ${props => props.zIndex[0]};
  left: ${props => props.offsetX[0].left};
  right: ${props => props.offsetX[0].right};
  margin-top: ${props => props.offsetY[0]};

  ${media.md`
    order: ${props => props.gridOrder[1]};
    z-index: ${props => props.zIndex[1]};
    left: ${props => props.offsetX[1].left};
    right: ${props => props.offsetX[1].right};
    margin-top: ${props => props.offsetY[1]};
  `}
`;

export default ({
  type,
  image,
  video,
  layoutMobile,
  layoutDesktop,
  ...props
}) => {
  const layoutM = layoutMobile || defaultLayout;
  const layoutD = layoutDesktop || defaultLayout;

  const columnWidth = getGridValues(layoutM.gridWidth, layoutD.gridWidth, 12);

  return (
    <Item
      w={columnWidth}
      o={getGridValues(layoutM.gridOffset || 0, layoutD.gridOffset || 0, 0)}
      gridOrder={[layoutM.gridOrder || 0, layoutD.gridOrder || 0]}
      offsetX={[
        getOffset(layoutM.xOffsetDirection, layoutM.xOffsetValue),
        getOffset(layoutD.xOffsetDirection, layoutD.xOffsetValue),
      ]}
      offsetY={[layoutM.yOffsetValue || 0, layoutD.yOffsetValue || 0]}
      zIndex={[layoutM.zIndex || 0, layoutD.zIndex || 0]}
      {...props}
    >
      {type === 'atomMoodboardImage' && (
        <Image columnWidth={columnWidth} {...image} />
      )}
      {type === 'atomMoodboardVideo' && (
        <Video columnWidth={columnWidth} {...video} />
      )}
    </Item>
  );
};

export const query = graphql`
  fragment moodboardItemSettingsData on SanityMoodboardItemSettings {
    gridOrder
    gridOffset
    gridWidth
    xOffsetDirection
    xOffsetValue
    yOffsetValue
    zIndex
  }

  fragment moodboardImageData on SanityAtomMoodboardImage {
    image {
      ...imageData
    }
    layoutMobile {
      ...moodboardItemSettingsData
    }
    layoutDesktop {
      ...moodboardItemSettingsData
    }
  }

  fragment moodboardVideoData on SanityAtomMoodboardVideo {
    video {
      ...videoData
    }
    layoutMobile {
      ...moodboardItemSettingsData
    }
    layoutDesktop {
      ...moodboardItemSettingsData
    }
  }
`;
