import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Box from '@/atoms/Box';

import theme from '@/styles/theme';

const Svg = styled(Box).attrs({ as: 'svg' })`
  box-sizing: content-box;
  width: 100%;
  height: auto;
  fill: none;
  stroke-width: 1px;

  /* responsive svgs on IE */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    padding-bottom: ${props => props.$height / props.$width}%;
    height: 1px;
    overflow: visible;
  }

  * {
    vector-effect: non-scaling-stroke;
  }
`;

export default ({ svg, width, height, spacing = 'large', ...props }) => {
  return (
    <Grid.Container as="section" pb={theme.spacing[spacing]} {...props}>
      <Svg
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMin slice"
        xmlns="http://www.w3.org/2000/svg"
        $width={width}
        $height={height}
        dangerouslySetInnerHTML={{ __html: svg }}
      />
    </Grid.Container>
  );
};

export const query = graphql`
  fragment moduleSvgData on SanityModuleSvg {
    svg
    width
    height
    spacing
  }
`;
